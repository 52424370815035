import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return(
            <footer>
                <hr />
                <p>Copyright HenryLo &copy; 2019</p>
            </footer>
        );
    }
}

export default Footer;